



































































































































































































































































































































































































































































































































































































































































.modal-cadastro-form .theme--light.v-data-table {
  background-color: #FFF;
}

.br-btn-entity-products {
  margin-top: 10px;
  min-width: auto !important;
  font-size: 0.8em !important;
}

.modal-cadastro-form::v-deep .v-badge__badge {
    top: 0px !important;
    /* right: 21px; */
    left: 80px !important;
}
